<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 9C18 13.9705 13.9705 18 9 18C4.02943 18 0 13.9705 0 9C0 4.02943 4.02943 0 9 0C13.9705 0 18 4.02943 18 9ZM8.25651 13.455C8.00793 13.455 7.80651 13.2535 7.80651 13.005V7.72776C7.80651 7.47918 8.00793 7.27776 8.25651 7.27776H9.73818C9.98667 7.27776 10.1882 7.47918 10.1882 7.72776V13.005C10.1882 13.2535 9.98667 13.455 9.73818 13.455H8.25651ZM8.9973 6.13739C8.67519 6.13739 8.39898 6.03096 8.16894 5.8181C7.93881 5.60524 7.8237 5.34925 7.8237 5.0501C7.8237 4.75096 7.93881 4.49495 8.16894 4.28209C8.39898 4.06924 8.67519 3.96282 8.9973 3.96282C9.32238 3.96282 9.5985 4.06924 9.82575 4.28209C10.0558 4.49495 10.1709 4.75096 10.1709 5.0501C10.1709 5.34925 10.0558 5.60524 9.82575 5.8181C9.5985 6.03096 9.32238 6.13739 8.9973 6.13739Z"
      fill=""
    />
  </svg>
</template>
